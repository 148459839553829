import React from 'react';
import PropTypes from 'prop-types';
import Template from 'templates';
import { GetServerSideProps } from 'next';
import { generatePage } from 'lib/wordpress/pages';

const Homepage = ({ pageData }) => <Template data={pageData} />;

Homepage.defaultProps = {
    pageData: {},
};

Homepage.propTypes = {
    pageData: PropTypes.oneOfType([PropTypes.object]),
};

export default Homepage;

export const getServerSideProps: GetServerSideProps = async ({ query }) => {
    const data = await generatePage(query);
    return {
        notFound: data?.notFound,
        props: {
            layout: { ...data?.layout },
            pageData: { ...data?.pageData },
            title: data?.title,
        },
    };
};
